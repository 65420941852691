//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => ({
    icons: [
      { name: "mdi-instagram", link: "https://www.instagram.com/matjrah_app/" },
      { name: "mdi-twitter", link: "https://twitter.com/matjrah_app" },
      {
        name: "mdi-whatsapp",
        link: "https://api.whatsapp.com/send?phone=966555252984",
      },
    ],
  }),
  computed: {
    links() {
      return [
        { name: "الرئيسية", to: "/" },
        { name: "الأسعار", to: "/prices" },
        { name: "المميزات", to: "/features" },

      ];
    },
    linksKW() {
      return [
        { name: "تبيع على انستجرام؟", to: "/instagram" },
        { name: "الأسئلة الشائعة", to: "/faqs" },
        { name: "إنشاء متجر الكتروني متكامل", to: "/full-store" },
      ];
    },
    otherLinks() {
      return [
        { name: "الأسئلة الشائعة", to: "/faqs" },
        // { name: "المدونة", href: "https://blog.tajadad.app/" },
        { name: "تواصل معنا", to: "/contact-us" }

        // { name: "تواصل معنا", to: "/contact-us" },
      ];
    },
  },
};
